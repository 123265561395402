import React from 'react'
import './Projectoverview.css'
import ProjectOverImage from '../../asserts/ProjectOverView/Rectangle 30.png'
import ProjectOverImageTwo from '../../asserts/ProjectOverView/Rectangle 52.png'


function Projectoverviewtwomain() {
  return (
    <div>
       <div className='Projectoverviewonemain' >
      <div className='ModularServersbanner' >
         <div className='ModularServersbanner-left' >
<h1>Data Center Rack Series</h1>
<p>Airigo's Data Center series offers scalable server racks for all data center sizes.</p>
         </div>
         <div className='ModularServersbanner-right' >
          <img src={ProjectOverImage} alt="" width="75%"  />
         </div>
      </div>
      <div className='ModularServersbannerTwo' >
       <div className='ModularServersbannerTwo-Left' >
          <img src={ProjectOverImageTwo} alt="" width="75%" />
       </div>
       <div className='ModularServersbannerTwo-Right' >
     <p>They accommodate  free cooling systems and maximize resource efficiency with monitoring and control features.</p>
       </div>

      </div>
    </div>
    </div>
  )
}

export default Projectoverviewtwomain
