import { Box, Button, Card, CardMedia, Container, Typography } from '@mui/material';
import React from 'react';
import CardOneImage from '../../../src/asserts/Aboutuspage/Rectangle 26.png'
import CardTwoImage from '../../../src/asserts/Aboutuspage/Rectangle 27.png'

function Whatwestand() {
  return (
    <div>
      <Box sx={{ width: "100%", background: "#ffffff" }} >
      <Box sx={{width:"100%", 
      display:"flex",
      flexDirection:"column",
      marginTop:"4%",
      justifyContent:"center",
      alignItems:"center"
      }} >
      <Button variant="contained" sx={{
            background:"rgba(206, 244, 242, 1)",
            color:"rgba(32, 65, 140, 1)",
            borderRadius:"5px",
            fontSize:"14px",
            fontWeight:"700",
            width:"120px",
            fontFamily: "Urbanist, sans-serif",
            boxShadow: 'none', // Remove any hover effect like shadow

            height:"40px",
            textTransform:"capitalize",
            '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(206, 244, 242, 1)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },
    '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
    marginTop:"3vh",
    }

           }} >OUR VALUES</Button>
           <Typography variant='h4' sx={{
            marginTop:"1%",
            fontFamily: "Urbanist, sans-serif",
            fontWeight:"700",
            fontSize:"42px",
            color:"rgba(59, 66, 75, 1)",
            '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
            fontSize:"28px",
            marginTop:"4%",

           
            }
        

           }}>
           What We Stand By
           </Typography>
      </Box>
      <Container maxWidth component="main" sx={{ pt: 5,
      pb:15,
       width:"90%", 
       background: "#ffffff", 
       display:"flex",
       justifyContent:"space-between",
      flexWrap:"wrap",
      '@media (max-width:1440px)': { // Styles for screens with a maximum width of px (mobile view)
           
        width:"95%", 


        },
      '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
            width:"90%",
            paddingBottom:"10px",
            pt: 4,
            

        },
      
      }}>
      <Card sx={{ 
      maxWidth: 644,
      background:"none",
      pb:2, 
      textAlign:"center",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      borderRadius:"10px" ,
      '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
              marginBottom:"5vh",
             
        },
        '@media (max-width:1400px)': { // Styles for screens with a maximum width of px (mobile view)
          maxWidth: 520,
        },
      
      }}>
      <CardMedia
        sx={{ height: 300,marginBottom:"20px",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
             height:170,
        },
        }}
        image={CardOneImage}
      />
      <Box sx={{display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      textAlign:"center",
      alignItems:"center",
      padding:"0vh 5vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
          padding:"0vh 1vh"
      }
      
      }} > 
      <Typography variant="body"
         color="rgba(59, 66, 75, 1)" 
         fontSize={30} 
         fontFamily="Urbanist" 
         fontWeight={700}
         marginBottom={"2vh"}
     sx={{      '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)


     fontSize:"22px",
     }
}}
        >
        Our Vision
        </Typography>
        <Typography paragraph  
                sx={{color:"rgba(121, 126, 132, 1)", 
                fontSize:"16px",
                fontWeight:"500",
                width:"85%",
                 fontFamily: "Urbanist, sans-serif",
                 '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
                    fontSize:"13.5px",
                    width:"95%",

                  }
                 }}

        >
        Airigo's vision is to lead India to the forefront of the global tech
industry through pioneering developments in server and high performance computing technologies.
        </Typography>
      </Box>
       
     
 
    </Card>
    <Card sx={{ maxWidth: 644,
    background:"none",pb:2, 
    borderRadius:"10px",
    marginleft:"5vh",
    textAlign:"center",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",

    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              marginBottom:"5vh",
              height:"55%",
        },
        '@media (max-width:1400px)': { // Styles for screens with a maximum width of px (mobile view)
          maxWidth: 520,
             
        },
      
     }}>
      <CardMedia
        sx={{ height: 300,marginBottom:"20px",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
             height:160,
        },
         }}
        image={CardTwoImage}
      />
         <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",padding:"0vh 2.8vh",
alignItems:"center",
        
          '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
          padding:"0vh 1vh"
      }
         }} > 
      <Typography variant="body"
         color="rgba(59, 66, 75, 1)" 
         fontSize={30} 
         fontFamily="Urbanist" 
         fontWeight={700}
         marginBottom={"2vh"}
         sx={{      '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)


fontSize:"22px",
}
}}
        >
        Our Mission
        </Typography>
        <Typography paragraph  
                 sx={{color:"rgba(121, 126, 132, 1)", 
                  fontFamily: "Urbanist, sans-serif",
                  fontSize:"16px",
                fontWeight:"500",
                width:"90%",
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of px (mobile view)
                    fontSize:"13.5px",
                    width:"95%",

                  }
                  }}

        >
        Our mission is to propel India's technological landscape into the future through dedicated innovation and indigenous manufacturing. Embracing the 'Make in India' and 'Made for India' initiatives, 
        we strive to develop products and solutions that resonate with the unique needs of the Indian market.
        </Typography>
      </Box>

      
    </Card>
   
    
      </Container>
    </Box>
    </div>
  )
}

export default Whatwestand
