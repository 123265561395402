import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import DataBottleNeckImage from '../../../src/asserts/Aboutuspage/DataBottleNeckImage.png'
import './Aboutus.css'

function DataBottleneck() {
  return (
    <div>
    <Box sx={{ 
     width: "100%", 
    display:"flex",
    justifyContent:"center", 
    background: "#ffffff",
    marginTop:"10vh",
    marginBottom:"5vh",
    height:"100%" ,
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      flexDirection:"column",
      height:"80vh" ,
      marginTop:"22vh",
      marginBottom:"5vh",
    },
    }} >
 
 <Box sx={{width:"46%",
   height:"100%", 
   background:"#ffffff",
   
   display:"flex",
   alignItems:"center",
   justifyContent:"center",
  
   marginTop:"-15vh",
   '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
     width:"90%",
     height:"50%", 
     marginBottom:"10vh",
     marginLeft:"5%"
    },
   }}  >
     <img src={DataBottleNeckImage} alt="" width="90%"   loading='lazy' />
   </Box>
 <Box sx={{width:"48%",height:"100%", background:"#ffffff",
  marginLeft:"1vw",marginTop:"-12vh",
  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
     width:"95%",
     marginTop:"-6vh",
    },
  }}  >
 <Button variant="contained" sx={{
         background:"rgba(10, 200, 188, 0.2)",
         color:"rgba(32, 65, 140, 1)",
         borderRadius:"5px",
         fontSize:"12px",
         fontFamily:"Urbanist, sans-serif",
         boxShadow: 'none', // Remove any hover effect like shadow

         '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(206, 244, 242, 1)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },

         fontWeight:"700",
         width:"120px",
         height:"40px",
         textTransform:"capitalize",
         '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      
     marginLeft:"8%"
    },

        }} > THE PROBLEM</Button>
 <Typography variant='h1' sx={{
   fontSize:"46px",
   fontWeight:"700",
   fontFamily: "Urbanist, sans-serif",

   color:"rgba(59, 66, 75, 1)",
   marginTop:"3%",
   '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      fontSize:"26px",
      marginTop:"6%",

      marginLeft:"7%",
      fontWeight:"700",
    },

 }} >
The Data Bottleneck     </Typography>

<Typography variant='h6' sx={{
   fontSize:"26px",
   fontWeight:"500",
   width:"75%",
   color:"rgba(59, 66, 75, 1)",
   fontFamily:"Urbanist, sans-serif",
   lineHeight:"36px",
   marginTop:"1vh",
   '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      fontSize:"16px",
      marginLeft:"7%",
    fontWeight:"500",
      lineHeight:"22px",
      width:"86%",


    },

 }} >
The Growing Gap Between Servers and the Future of Technology

     </Typography>



 <Typography paragraph sx={{
   color:"rgba(121, 126, 132, 1)",
   fontSize:"16px",
   marginTop:"3%",
   fontWeight:"500",
   width:"75%",
   fontFamily:"Urbanist, sans-serif",

   '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      fontSize:"14px",
      marginLeft:"7%",
      marginTop:"2vh",
      width:"88%",


    },

 }} >
The rise of powerful technologies like generative AI and ever-expanding databases exposes limitations in current server hardware. Traditional systems struggle to keep pace with these demanding applications. This growing gap highlights the need for a fundamental shift in server architecture. 
New solutions must anticipate the needs of future technologies, not just react to current limitations.</Typography>
 

<Box sx={{ display: "flex", marginBottom: "5vh" }}>
          <Box sx={{
           '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      marginLeft:"6%"
    },
          }}>
       
        <Typography paragraph 
        sx={{ 
            display:"flex",
            alignItems:"center",
        color:"rgba(23, 61, 122, 1)" ,
        fontSize:"21px",
        fontWeight:"600",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '15px',
              fontWeight:"700"
            },
        }} >
          <span
          className='span-point'
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
              
            }}
          ></span> Performance Limitations for AI Workloads {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"rgba(23, 61, 122, 1)" ,
        display:"flex",
            alignItems:"center",
        fontSize:"21px",
        fontWeight:"600",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '15px',
              fontWeight:"700"
            },
        }} >
          <span
                    className='span-point'

            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Scalability and Infrastructure Flexibility {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"rgba(23, 61, 122, 1)",
        fontSize:"21px",
        display:"flex",
            alignItems:"center",
        fontWeight:"600",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '15px',
              fontWeight:"700"
            },
        }} >
          <span
                    className='span-point'

            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span>Computational Power and Efficiency {/* Add a span with the bullet class */}
        </Typography>
        
          </Box>

        
        </Box>

   </Box>
   
 </Box>
 </div>
  )
}

export default DataBottleneck
