import React from 'react'
import { Button } from '@mui/material'
import './Projectoverview.css'


function Applicationsofproducts() {
  return (
    <div>
      <div className='Applicationsofproducts' >
    <div className='whychooseus-content' >
        <h1>Applications of Our Product</h1>
    </div>
      <div className='Differentiators'>
        <div className='Applicationsofproducts-Cards'>
            <h2 className='Applicationsofproducts-firstheading' >Cloud Services</h2>
            <p>Built for the cloud, our servers deliver the scalability and efficiency needed for optimal cloud computing service.</p>
        </div>
        <div className='Applicationsofproducts-Cards'>
        <h2>AI and Machine Learning</h2>
<p>Powering the future of AI and ML. Our energy-efficient servers deliver the processing power needed for demanding tasks without sacrificing efficiency.</p>

        </div>
        <div className='Applicationsofproducts-Cards'>
        <h2>Containers and Microservices</h2>
<p>Our highly scalable servers ensure smooth operation and efficient resource allocation for your containerized applications, maximizing performance and agility.</p>

        </div>
        <div className='Applicationsofproducts-Cards'>
        <h2>Data Storage and Processing</h2>
<p>Don't be bogged down by big data.  Our servers provide the muscle to handle massive storage needs and complex processing tasks.</p>

        </div>
        <div className='Applicationsofproducts-Cards'>
        <h2>Network Traffic Processing</h2>
<p>Airigo servers handle massive data flow with minimal lag, ensuring smooth operation and reliable communication for your latency-sensitive applications.</p>

        </div>
        <div className='Applicationsofproducts-Cards'>
        <h2>Memory Intensive Compute</h2>
<p>Handle memory-hungry tasks with ease. Airigo servers boast high memory bandwidth and capacity.</p>

        </div>
      </div>
    </div>
    </div>
  )
}

export default Applicationsofproducts
