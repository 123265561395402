import React, { useEffect } from 'react'
import Banner from './Homepage/Banner'
import Homeaboutus from './Homepage/Homeaboutus'
import Whatweoffer from './Homepage/Whatweoffer'
import Madeinindia from './Homepage/Madeinindia'

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <div>
      <Banner/>
      <Homeaboutus/>
      <Whatweoffer/>
      <Madeinindia/>
    </div>
  )
}

export default Home
