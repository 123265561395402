import * as React from 'react';
import './Navbar.css';
import Logo from '../asserts/Homepageimages/Airigo white logo.png'
import Logo2 from '../asserts/Homepageimages/Airigo blue logo.png'
import closeIcon from '../asserts/Homepageimages/x-close.png'
import { Box, Typography } from '@mui/material';
import { Link,NavLink,useLocation  } from 'react-router-dom';
import Navmenu from '../../src/asserts/Homepageimages/HamburgerMenu.png'
import { useState } from 'react';
import RightArrow from '../../src/asserts/Homepageimages/arrow-narrow-right.png'
import { useEffect } from 'react';



function Header() {

  const [Open, setOpen] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setOpen(true); // Reset state to true when route changes
  }, [location]);

  return (
    <div>
    {Open && <div className='Navbar' >
      <div className='logo-class' >
      <NavLink to="/" >
      <img src={Logo} alt="" width="80%" />

      </NavLink>
      </div>

<div className='nav-links' >
<NavLink  className='links' to="/About" style={{textDecoration:"none",color:"#ffffff"}} > <p>About us</p></NavLink>
<NavLink   className='links' to="/OurProducts" style={{textDecoration:"none",color:"#ffffff"}} > <p>Our Products</p></NavLink>
<NavLink   className='links' to="/Contact" style={{textDecoration:"none",color:"#ffffff"}} > <p>Contact Us</p></NavLink>
<div>
<img src={Navmenu} onClick={() => setOpen(!Open)} className='Navmenu'  alt="" />
</div>

</div>
    

         
      </div>}



  <div className='Navbartwo' >
      <div className='logo-class2' >
      <NavLink to="/" >
      <img src={Logo2} alt="" width="80%" />

      </NavLink>
      </div>

<div className='nav-links' >
<NavLink  className='links' to="/About" style={{textDecoration:"none",color:"#ffffff"}} > <p>About us</p></NavLink>
<NavLink   className='links' to="/OurProducts" style={{textDecoration:"none",color:"#ffffff"}} > <p>Our Products</p></NavLink>
<NavLink   className='links' to="/Contact" style={{textDecoration:"none",color:"#ffffff"}} > <p>Contact Us</p></NavLink>
<div>
<img src={closeIcon} onClick={() => setOpen(!Open)}  className='NavmenuTwo'  alt="" />
</div>

</div>
    

         
      </div> 
     
     
{!Open && <div className='NavbarTwoLinks' >
<Link  to="/About" style={{textDecoration:"none"}} >
<div className='AboutUs  NavbarTwo-Link'>
<div>
  <p>About us</p>
</div>
<div>
<img src={RightArrow} alt="" />
</div>
</div>
</Link>

<Link to="/OurProducts" style={{textDecoration:"none"}}  >
<div className='OurProducts NavbarTwo-Link'>
<div>
  <p>Our Products</p>
</div>
<div>
<img src={RightArrow} alt="" />
</div>
</div>
</Link>
<Link  to="/Contact" style={{textDecoration:"none"}}  >
<div className='Contactus NavbarTwo-Link' >
<div>
  <p>Contact Us</p>
</div>
<div>
<img src={RightArrow} alt="" />
</div>
</div>
</Link>

<div className='nav-overlay' >

</div>

</div>
}
      

      </div>
   
  );
}

export default Header;
