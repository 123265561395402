import React, { useEffect } from 'react';
import Aboutusbanner from './Aboutusbanner';
import Aboutuscontent from './Aboutuscontent';
import Whatwestand from './Whatwestand';
import DataBottleneck from './DataBottleneck';
import Oursolution from './Oursolution';
import Whychooseus from './whychooseus';

function Aboutuspage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <div>
      <Aboutusbanner/>
      <Aboutuscontent/>
      <Whatwestand/>
      <DataBottleneck/>
      <Oursolution/>
      <Whychooseus/>
    </div>
  )
}

export default Aboutuspage
