import React from "react";
import './Footer.css'
import FooterLogo from '../../src/asserts/Homepageimages/Airigo blue logo.png'
import MobileIcon from '../../src/asserts/Homepageimages/mobile icon.png'
import Location from '../../src/asserts/Homepageimages/Location Icon.png'
import Message from '../../src/asserts/Homepageimages/Message.png'
import { Link } from "react-router-dom";





const Footer = () => {
  return (
    <div className="Footer" >
      <div className="footer-content" >
      <Link to="/" >
      <div>
      <img src={FooterLogo} alt="" width="32%"  />
      </div>
      </Link>
      
        <p>Airigo leads technological innovation in India with modular servers, data centers, and supercomputers. Our energy-efficient processors support modern data centers and high-performance computing. We empower businesses with low-latency databases, virtualization, software-defined infrastructure, cloud services, and big data analytics. </p>
        <div className="Note-Content" >
<p>Note: The actual products might differ in terms of looks from the images used in website</p>
</div>
      </div>
    
      <div className="quicklinks-help" >

      
      <div className="quick-links" >
        <h3>Quick Links</h3>
        <Link to="/" style={{textDecoration:"none"}}  >
        <p>Home</p>
        </Link>
        <Link to="/About" style={{textDecoration:"none"}}  >
        <p>About us</p>
        </Link>
        <Link to="/OurProducts" style={{textDecoration:"none"}}  >
        <p>Our Products</p>
        </Link>


        </div>
        <div className="help" >
        <h3>Help</h3>
        
        <Link to="/Contact" style={{textDecoration:"none"}}  >
        <p>Contact us</p>
        </Link>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        </div>
        </div>
        <div className="footer-contact" >
        <h3>Contact</h3>

        <div className="Contact-info" > <img src={MobileIcon} alt=""  width="4%" height="4%" />  <p>+91 9773534737</p> </div>
        
        <div className="Contact-info" > <img src={Message} alt=""  width="4%"  height="4%" /> <p>contact@airigo.in</p> </div>
        <div className="Contact-info" >  <img src={Location} alt=""  width="4%"  height="4%" /><p>Address: 503 kushal bazar, Nehru place New Delhi 110019</p> </div>

        </div>
       
    </div>
  );
};

export default Footer;

