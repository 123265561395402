import React from 'react';
import './Projectoverview.css'
import ProjectOverImage from '../../asserts/ProjectOverView/ModuleServerSeries.png'
import ProjectOverImageTwo from '../../asserts/ProjectOverView/Serverseriessecondimage.png'



function Projectoverviewonemain() {
  return (
    <div className='Projectoverviewonemain' >
      <div className='ModularServersbanner' >
         <div className='ModularServersbanner-left' >
<h1>Modular Servers Series</h1>
<p>Airigo's modular server series offers superior scalability with compute modules supporting additional CPUs</p>
         </div>
         <div className='ModularServersbanner-right'  >
         
          <img src={ProjectOverImage} alt="" width="80%" loading='lazy' />
         </div>
      </div>
      <div className='ModularServersbannerTwo' >
       <div className='ModularServersbannerTwo-Left' >
          <img src={ProjectOverImageTwo} alt="" width="75%" loading='lazy' />
       </div>
       <div className='ModularServersbannerTwo-Right' >
     <p>The system utilizes modern controllers and comes with a range of chassis options for flexible configurations, maximizing performance for demanding applications.</p>
       </div>

      </div>
    </div>
  )
}

export default Projectoverviewonemain
