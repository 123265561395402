import React from 'react'
import Bgimage from '../../../src/asserts/Homepageimages/BannerBgImage.png'
import MobileViewBgimage from '../../../src/asserts/Homepageimages/MobileViewBgImage.png'
import './Banner.css'
import { Button } from '@mui/material'
import Rightserverimage from '../../../src/asserts/Homepageimages/BgImageServer.png'
import { Link } from 'react-router-dom'


function Banner() {
  return (
    <div>
      <div className='banner' >
      <div className='banner-content'>
        <div className='banner-content-left' >
             <h1>Airigo: Powering the future of IT infrastructure</h1>
             <p>Crafting the future of server technology with our cost- effective powerhouse: Airigo- your future proof choice</p>
             <Link to="/OurProducts" style={{textDecoration:"none"}}  >
             <Button variant="contained" sx={{
              background:"rgba(10, 200, 188, 1)",
              borderRadius:"10px",
              fontSize:"14.5px",
              fontWeight:"500",
              marginTop:"4%",
              fontFamily: "Urbanist, sans-serif",
              width:"165px",
              height:"45px",
              textTransform:"capitalize",
              '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                marginLeft:"5vh",
                width:"140px",
                fontWeight:"400",


                
            
              },

             }} >View Products</Button>
             </Link>

            
        </div>
        <div className='banner-content-right' >
<img src={Rightserverimage} alt=""  width="70%" />
</div>
      </div>
      <img src={MobileViewBgimage} className='BgBannerMobileImage' alt="" width="100%" />

       <img src={Bgimage} className='BgBannerWebImage' alt="" width="100%" height="87%" />
      </div>
    </div>
  )
}

export default Banner
