import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import offerimageone from '../../../src/asserts/Homepageimages/1000020635.png'
import offerimagetwo from '../../../src/asserts/Homepageimages/image 20.png'
import { Link } from 'react-router-dom'

function Whatweoffer() {
  return (
    <div>
      <Box sx={{ width: "100%",
       background: "#ffffff",
       display:"flex",
       flexDirection:"column",
       justifyContent:'center',
       height:"100%",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                height:"140vh",
                marginTop:"5%",
              },
       }} >
   
    

<Box sx={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20vh",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                flexDirection:"column",
               alignItems:'center',
               
              },

}} >
    <Box sx={{width:"450px",height:"440px",
        borderRadius:"10px", 
  
    background:"#ffffff",
    boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display:"flex",
    flexDirection:"column",
    position:"relative",
    textAlign:"center",
    alignItems:"center",
    fontFamily: "Urbanist, sans-serif",

    marginRight:"8vh",
    '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"320px",
      height:"400px",
      marginRight:"0vh",
      
                       
      },
    
    }} >

    <Box sx={{position:"absolute",top:"-20%"}} >
    <img src={offerimageone} alt=""   width="80%"  loading='lazy' />
    </Box>

      
      <Typography variant='h4' sx={{
      fontSize:"26px",
      fontWeight:"700",
      color:"rgba(59, 66, 75, 1)",
      textAlign:"center",
      fontFamily: "Urbanist, sans-serif",

      marginTop:"27vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
     
      marginTop:"40%",
                       
      },
      }} >
      Modular Servers Series
      </Typography>
      <Typography paragraph sx={{
        color:"rgba(121, 126, 132, 1)",
      width:"90%",
      textAlign:"center",
      fontSize:"16px",
      marginTop:"3%",
      marginBottom:"2%",
      fontWeight:"500",          
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"14px",

          }
}} >
      Airigo's modular server series offers superior scalability with compute modules supporting additional CPUs. The system utilizes modern controllers and comes with a range of chassis options for flexible configurations, maximizing performance for demanding applications.
      </Typography>
      <Link to="/ModularServersSeries" >
      <Typography sx={{color:"rgba(10, 200, 188, 1)",  
        fontFamily: "Urbanist, sans-serif",
        fontWeight:"700",
textDecoration:"underline",
}} >Read More</Typography>
      </Link>

    </Box>
    <Box sx={{width:"450px",height:"440px", 
    borderRadius:"10px", 
    background:"#ffffff",
    boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display:"flex",
    flexDirection:"column",
    position:"relative",
    fontFamily: "Urbanist, sans-serif",

    textAlign:"center",
    alignItems:"center",
    marginLeft:"8vh",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"320px",
      height:"400",
      marginLeft:"0vh",
      marginTop:"20vh",
                       
      },
    }} >

    <Box sx={{position:"absolute",top:"-25%"}} >
    <img src={offerimagetwo} alt=""   width="68%"  loading='lazy' />
    </Box>

      
      <Typography variant='h4' sx={{
      fontSize:"26px",
      fontWeight:"700",
      color:"rgba(59, 66, 75, 1)",
      textAlign:"center",
      marginTop:"27vh",
      fontFamily: "Urbanist, sans-serif",

      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
     
     marginTop:"60%",
                      
     },

      }} >
Data Center Rack Series
      </Typography>
      <Typography paragraph sx={{color:"rgba(121, 126, 132, 1)",
       fontSize:"16px",
      fontWeight:"500", 
      marginBottom:"5%",

      marginTop:"3%",
      width:"90%",textAlign:"center",
              fontFamily: "Urbanist, sans-serif",
              '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"14px",

          }
}} >
      Airigo's Data Center series offers scalable server racks for all data center sizes. They accommodate  free cooling systems and maximize resource efficiency with monitoring and control features.      </Typography>
      <Link to="/DataCenterRackSeries" >
      <Typography sx={{color:"rgba(10, 200, 188, 1)",
          fontFamily: "Urbanist, sans-serif",
          marginTop:"10%",
          fontWeight:"700",


      textDecoration:"underline"}} >Read More</Typography>

      </Link>

    </Box>

</Box> 



    
    </Box>
    </div>
  )
}

export default Whatweoffer
