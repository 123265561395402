import { Box, Typography } from '@mui/material'
import React from 'react'
import Aboutusbannerimg from '../../../src/asserts/Aboutuspage/Aboutusbanner.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';


function Contactusbanner() {
  return (
    <div>
     <Box sx={{backgroundSize:"cover",marginTop:"5vh",
     '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      marginTop:"10vh",
     }
     }} >
<Box sx={{
width:"100%",
height:"85vh",
background:"transparent",
position:"absolute",
display:"flex",
justifyContent:"center",
alignItems:"center",

'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
  height:"40vh"
}

}} >
<Box>
<Typography variant='h3' sx={{color:"white",fontSize:"50px",fontWeight:"700"}} >
Contact Us
</Typography>
<Typography paragraph 
          sx={{ 
          color:"#ffffff" ,
          textAlign:"center",
          marginTop:"1.5vh",
          fontSize:"22px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '10px',
              },
          }} >
          <span style={{marginRight:"15px"}} >Home</span>
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"12px",
              }}
            ></span>  Contact Us {/* Add a span with the bullet class */}
          </Typography>
</Box>



</Box>
 <LazyLoadImage
          alt=""
          src={Aboutusbannerimg}
          width="100%"
          className='about-image-height'
          effect="blur" // Optional blur effect while loading
        /></Box>
    </div>
  )
}

export default Contactusbanner
