import React from 'react'
import './Versitilecomp.css'
import { Box, Typography } from '@mui/material'
import Comboimage from '../../../src/asserts/ProjectOverView/VersitileComboimg.png'
import ArrowImage from '../../../src/asserts/ProjectOverView/Arrow 1.png'
import chasisimage01 from '../../../src/asserts/ProjectOverView/Group 32.png'
import chasisimage02 from '../../../src/asserts/ProjectOverView/Group 33.png'
import chasisimage03 from '../../../src/asserts/ProjectOverView/Group 36.png'





function Versitilecomp() {
  return (
    <div className='Versitilecomp' >
      <Box sx={{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
      }}>
       <Typography variant='h1' sx={{fontWeight:"700",      
          fontSize:"46px",
          marginBottom:"2vh",
          fontFamily: "Urbanist, sans-serif",
          marginTop:"5%",
          color:"rgba(59, 66, 75, 1)",
          textAlign:"center",

         '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
               fontSize:"26px",
               marginTop:"12%",

               width:"75%",
              },
        }} >
        Versatile Combinations for Your Needs
        </Typography>
        <Typography paragraph sx={{color:"rgba(121, 126, 132, 1)",
        fontSize:"16px",
        width:"70%",
        textAlign:"center",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
          width:"80%",
          fontSize:"14px",


         }
        }} >
        Our server company takes pride in offering versatile chassis configurations that can be arranged in multiple ways to suit your unique server requirements. Whether you need a compact setup for limited space or a scalable solution for expanding operations, our customizable chassis options ensure that you have the flexibility to design your server rack layout precisely as per your needs.
        </Typography>

        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
          <img src={Comboimage} alt=""  width="70%" className='comboimageWidth' />
          <img src={ArrowImage} alt="" width="5%" />
        </Box>
       
       <Box sx={{
        background:"rgba(236, 255, 254, 1)",
        width:"70%",
        height:"220px",
        display:"flex",
        flexDirection:"column",
        alignItems:'center',
        borderRadius:"10px",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
          height:"85vh",
          width:"82%",
          padding:'5% 0%',
          marginBottom:"3%",


        }
        }} >
        <Typography sx={{
          textAlign:"center",
          marginTop:"2%",
          marginBottom:"2%",
          fontFamily: "Urbanist, sans-serif",
          fontSize:"18px",
          fontWeight:"500",
          color:"rgba(23, 61, 122, 1)",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"16px",
            marginTop:"5%",
            width:"70%",
            textAlign:"center"

          }

          }} >
        Any combination of computing modules in the chassis is available.
        </Typography>

        <Box sx={{
            display:"flex",
        width:"100%",
        height:"80%",
        justifyContent:"space-around",
        alignItems:"center",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
         flexDirection:"column",
        }
        }} >

<Box sx={{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  flexDirection:"column",

    width:"20%",
    height:"80%",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"80%",
        }
}} >
<img src={chasisimage01} alt=""  width="80%" />
<Typography sx={{
  fontSize:"16px",
  fontFamily: "Urbanist, sans-serif",
  fontWeight:'500',
  color:"rgba(59, 66, 75, 1)",
  marginBottom:"20%",
  
}} >
6 Servers in a chassis
</Typography>
</Box>
<Box sx={{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  flexDirection:"column",
    width:"20%",
    height:"80%",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"80%",
        }
}} >
<img src={chasisimage02} alt=""  width="80%" />
<Typography sx={{
  fontSize:"16px",
  fontFamily: "Urbanist, sans-serif",
  fontWeight:'500',
  color:"rgba(59, 66, 75, 1)",
  marginBottom:"20%",
  
}} >
7 Servers in a chassis
</Typography>
</Box>
<Box sx={{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
flexDirection:"column",
    width:"20%",
    height:"80%",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"80%",
        }
    
}} >
<img src={chasisimage03} alt=""  width="80%" />
<Typography sx={{
  fontSize:"16px",
  fontFamily: "Urbanist, sans-serif",
  fontWeight:'500',
  color:"rgba(59, 66, 75, 1)",
  marginBottom:"20%",
  
}} >
8 Servers in a chassis
</Typography>
</Box>

        </Box>


       </Box>

        <Typography paragraph sx={{color:"rgba(121, 126, 132, 1)",
        fontSize:"16px",
        marginTop:"3%",
        width:"60%",
        textAlign:"center",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
       color:"rgba(121, 126, 132, 1)",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
          fontSize:"14px",
          width:"80%",
         }
        }} >
 With our expertise and adaptable chassis designs, you can optimize space utilization, improve airflow, and simplify maintenance, making your server infrastructure more efficient and effective.        </Typography>
       
        
      </Box>
    </div>
  )
}

export default Versitilecomp
