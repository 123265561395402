import { Box, Typography } from '@mui/material'
import React from 'react'
import Maincardimage from '../../asserts/ProjectOverView/DataCenterSeriesGroup.png'



function Projectoverviewspecificationtwo() {
  return (
    <div>
      <div className='Projectoverviewspecifications' >
  
  <div className='Projectoverviewspecifications-left' >
  <div className='Projectoverviewspecifications-leftCard'>
            <h3 className='Specifications-heading'>Data center 19" OCP</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul  className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                    Variants of models and configurations for any task
                  </li>
                  <li>
                    Effective use any type of air cooling
                    <ul>
      <li>Freecooling</li>
      <li>Chiller cooling</li>
    </ul>
                  </li>
                  <li>
                    Saving resources of power supply and cooling system
                  </li>
                  <li>
                    Mature monitoring and control system
                  </li>
                  <li>
                    Protect investment in chassis
                  </li>
                  <li>
                    Reduced support costs
                  </li>
          
                </ul>
              </Box>
            </Box>
          </div>
  <div className='Projectoverviewspecifications-leftCard'>
            <h3 className='Specifications-heading'>Typical rack Data Center</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                    4 chassis and 40 servers in rack max.
                  </li>
                  <li>
                    24U in rack max.
                  </li>
                  <li>
                    3840 cores, 60 TB RAM in rack max.
                  </li>
                  <li>
                  320 SSD NVMe M.2 (22110) (raw - 2,5 PB) - in rack max.
                  </li>
                  <li>
                    Power consumption typical - 2,6 KW
                  </li>
                  <li>
                    Power consumption peak - 21,5 KW
                  </li>
                  <li>
                    475 Tflops in rack max.
                  </li>
                  <li>
                    560x 1-40 GbE & 80x 100/200 Gbps max.
                  </li>
                </ul>
              </Box>
            </Box>
          </div>

  </div>
  <div className='Projectoverviewspecifications-center' >
  <h2 className='Overview-center-heading' >Product Overview</h2>
<div className='Projectoverviewspecifications-MainCard' >
<img src={Maincardimage} alt=""  width="70%"/>
<h2>DATA CENTER SERIES</h2>
</div>
</div>
   <div className='Projectoverviewspecifications-right' >


   <div className='Projectoverviewspecifications-rightCard'>
            <h3 className='Specifications-heading'>Typical rack HPC</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                    8 chassis and 80 servers in rack max.
                  </li>
                  <li>
                    48U in rack max.
                  </li>
                  <li>
                    7680 cores, 120 TB RAM in rack max.
                  </li>
                  <li>
                    Power consumption typical - 5,2 KW
                  </li>
                  <li>
                    Power consumption peak-43,1 KW
                  </li>
                  <li>
                    950 Tflops in rack max.
                  </li>
                  <li>
                  Available connections are Ethernet and InfiniBand
                                    </li>
                </ul>
              </Box>
            </Box>
          </div>
 <div className='Projectoverviewspecifications-rightCard'>
            <h3 className='Specifications-heading'>HPC</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                    High density of computing resources
                  </li>
                  <li>
                    High scalability
                  </li>
                  <li>
                    Mature monitoring and control system.
                  </li>
                  <li>
                    Reduced switching complexity
                  </li>
                  <li>
                    Unification of components
                  </li>
                  <li>
                    Long life cycle of chassis and modules
                  </li>
                </ul>
              </Box>
            </Box>
          </div>


</div>
</div>
    </div>
  )
}

export default Projectoverviewspecificationtwo
