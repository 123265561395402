import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Box } from "@mui/material";

const OutletComponent = () => {
  return (
    <Box >
      <Box>
        <Header />
      </Box>
      <Box>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default OutletComponent;
