import React, { useEffect } from 'react'
import Projectoverviewform from './Projectoverviewform'
import Applicationsofproducts from './Applicationsofproducts'
import Projectoverviewonetwo from './Projectoverviewtwomain'
import Projectoverviewspecificationtwo from './Projectoverviewspecificationtwo'


function Projectoverviewtwo() {

  
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <div>
       <div>
    <Projectoverviewonetwo/>
    <Projectoverviewspecificationtwo/>
    <Applicationsofproducts/>
    <Projectoverviewform/>
    </div>
    </div>
  )
}

export default  Projectoverviewtwo;
