import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#130123",
    },
  },
  typography: {},
});

export default muiTheme;
