import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OutletComponent from "../components/OutletComponent";
import Home from "./Home";
import Aboutuspage from "./Aboutus/Aboutuspage";
import Ourproducts from "./Ourproducts/Ourproducts";
import Contactus from "./Contactus/Contactus";
import Projectoverviewone from "./Projectoverview/Projectoverviewone";
import Projectoverviewtwo from './Projectoverview/Projectoverviewtwo';


const PageRoutes = () => {
  return (
    <Router>
    <Routes>
      <Route element={<OutletComponent />}>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<Aboutuspage />} />
        <Route path="/OurProducts" element={<Ourproducts />} />
        <Route path="/Contact"  element={<Contactus/>} />
        <Route path="/ModularServersSeries"  element={<Projectoverviewone/>} />
        <Route path="/DataCenterRackSeries"  element={<Projectoverviewtwo/>} />
      </Route>
    </Routes>
  </Router>
  );
};
export default PageRoutes;
