import React, { useRef} from 'react'
import { Button, TextField } from '@mui/material'
import './Contactus.css';
import MobileIcon from '../../asserts/ContactUs/Group 19.png'
import Location from '../../asserts/ContactUs/Group 20.png'
import Message from '../../asserts/ContactUs/Group 21.png'
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify'; // Import toast
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


const validationSchema = Yup.object().shape({
  firstname: Yup.string()
  .trim()
  .matches(/^[^\s].*$/, 'First Name cannot start with a space')
  .required('First Name is required'),
lastName: Yup.string()
  .trim()
  .matches(/^\S*$/, 'Last Name cannot contain leading or trailing spaces')
  .required('Last Name is required'),
  mobileNumber: Yup.string()
  .matches(/^[0-9]{10}$/, 'Invalid Mobile Number')
  .required('Mobile Number is required'), 
   email: Yup.string().email('Invalid email').trim().required('Email is required'),
  message: Yup.string().trim().required('Message is required'),
});


function Contactusform() {
  const sendEmail = (values, { resetForm }) => {
    emailjs
      .sendForm(
        'service_pgws0pi', // replace with your service ID
        'template_5vcg65q', // replace with your template ID
        '#contact-form',
        'vSQhxF5WARatgmfU7' // replace with your user ID
      )
      .then(
        (result) => {
          console.log('Email successfully sent:', result.text);
          toast.success('Email successfully sent'); // Display success toast
          resetForm();
        },
        (error) => {
          console.error('Email sending failed:', error.text);
          toast.error('Email sending failed'); // Display error toast
        }
      );
  };




  return (
    <>
    <ToastContainer/>
              <div className='GetistouchContactus-two'>
        <div className="contact-information" >
        <div className="Contact-infoTwo" >
        <div>
        <img src={MobileIcon} alt=""  width="60%" height="60%" />
        </div> 
        <div>
        <h5>Phone Numbers</h5>

        <p>+91 9773534737</p> 
        </div>
      
        </div>
        <div className="Contact-infoTwo" >
        <div>
        <img src={Message} alt=""  width="60%" height="60%" />
        </div> 
        <div>
        <h5>Email</h5>

        <p>contact@airigo.in </p> 
        </div>
      
        </div>
        <div className="Contact-infoTwo" >
        <div>
        <img src={Location} alt=""  width="60%" height="60%" />
        </div> 
        <div>
        <h5>Location</h5>
        <p>Address: 503 kushal bazar, <br /> Nehru place New Delhi 110019</p> 
        </div>
      
        </div>

        </div>
        <div className='contact-from'>
        <Button variant="contained" sx={{
              background:"rgba(10, 200, 188, 0.2)",
              color:"rgba(32, 65, 140, 1)",
              borderRadius:"5px",
              fontSize:"14px",
              fontWeight:"700",
              fontFamily: "Urbanist, sans-serif",

              width:"145px",
              height:"45px",
              textTransform:"capitalize",
              boxShadow: 'none', // Remove any hover effect like shadow

              '&:hover': {
      cursor:"text",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      fontSize:"12px",
      width:"130px",
      height:"40px",


    }
             
             }} >LET’S CONNECT</Button>
        <h2 style={{color:"rgba(59, 66, 75, 1)"}} >Reach Out to us</h2>
          <div className='contact-inputs-section'>
          <Formik
              initialValues={{
                firstname: '',
                lastName: '',
                mobileNumber: '',
                email: '',
                message: '',
              }}
              validationSchema={validationSchema}
              onSubmit={sendEmail}
            >
                          {({ errors, touched }) => (
                    
                            <Form id="contact-form">
                  <Field
                    as={TextField}
                    id="firstname"
                    name="firstname"
                    label="First Name"
                    variant="outlined"
                    
                    error={touched.firstname && !!errors.firstname}
                    helperText={touched.firstname && errors.firstname}


                    sx={{
                  mb: 3,
                  width:"41.5%",
                  marginRight:"4vh",
                  borderRadius: 2,
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"100%"
                  }
                }}
                  />
                  <Field
                    as={TextField}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}

                    sx={{
                  mb: 3,
                  width:"41.5%",
                  marginRight:"4vh",
                  borderRadius: 2,
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"100%"
                  }
                }}
                  />
                  <Field
                    as={TextField}
                    id="mobileNumber"
                    name="mobileNumber"
                    label="Mobile Number"
                    variant="outlined"
                    error={touched.mobileNumber && !!errors.mobileNumber}
                    helperText={touched.mobileNumber && errors.mobileNumber}
                    sx={{
                  mb: 3,
                  width:"41.5%",
                  marginRight:"4vh",
                  borderRadius: 2,
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"100%"
                  }
                }}
                  />
                  <Field
                    as={TextField}
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{
                  mb: 3,
                  width:"41.5%",
                  marginRight:"4vh",
                  borderRadius: 2,
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"100%"
                  }
                }}
                  />
                  <Field
                    as={TextField}
                    id="message"
                    name="message"
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    error={touched.message && !!errors.message}
                    helperText={touched.message && errors.message}

                    sx={{
                  mb: 3,
                  width:"87%",
                  marginRight:"4vh",
                  borderRadius: 2,
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"100%"
                  }
                }}
                  />
                     <Button
                type="submit"
                variant="outlined"
                sx={{
                  border: '1.2px solid white',
                  color: 'white',
                  width: '140px',
                  fontFamily: "Urbanist, sans-serif",
                  fontSize:"16px",
                  height: '50px',
                  borderRadius: '12px',
                  background:"rgba(10, 200, 188, 1)",
textTransform:"capitalize",
                  '&:hover': {
                    backgroundColor: 'rgba(10, 200, 188, 1)',
                    color: "#000",
                    border: "#fff" // Set the hover background color to transparent
                  },
                }}
              >
                Submit
              </Button>
                </Form>
          )}

            </Formik>
          </div>
        </div>
      </div>
    </>
      
    
  )
}

export default Contactusform
