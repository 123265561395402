import React from 'react'
import './Madeinindia.css'
import MadeIndiaimg from '../../asserts/Homepageimages/MadeInIndia.png'
import LeftAngle from '../../asserts/Homepageimages/LeftAngle.png'
import RightAngle from '../../asserts/Homepageimages/Rightimg.png'
import miieftbox from '../../asserts/Homepageimages/leftimgbox (1).png'
import miirightbox from '../../asserts/Homepageimages/leftimgbox (2).png'



function Madeinindia() {
  return (
    <div className='Madeinindia' >
      <div className='MiiLeftimage' >
      
      <div className='MiileftAngleimage' >
      <div className='miileftbox' >
<img src={miieftbox} alt="" />
      </div>
        <img src={LeftAngle} alt=""/>
      </div>
      </div>
      <div className='MiiCenterimage' >
      <img src={MadeIndiaimg} alt=""  width="30%" />
      <h3>Made In India</h3>
      <p>Airigo has emerged as a trailblazer in India's tech sector, pioneering advanced ARMx64 servers domestically. With its manufacturing facility set up in India, Airigo is at the forefront of fostering local innovation and reducing reliance on international imports in the server market.</p>
      </div>
      <div className='Miirightimage' >
      
      <div className='MiirightAngleimage' >
      <div className='miirightbox' >
<img src={miirightbox} alt="" />
</div>
      <img src={RightAngle} alt=""   />
      </div>
      </div>
    </div>
  )
}

export default Madeinindia
