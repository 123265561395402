import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import offerimageone from '../../../src/asserts/Homepageimages/1000020635.png'
import offerimagetwo from '../../../src/asserts/Homepageimages/image 20.png'




function Whatweoffer() {
  return (
    <div>
      <Box sx={{ 
        marginTop:"3%",
      height:"100%",
      width: "100%",
       background: "#ffffff",
       display:"flex",
       flexDirection:"column",
       justifyContent:'center', 
       marginBottom:"10vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                height:"175vh",
                marginTop:"0vh",
               
              },
       }} >
    <Box sx={{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      textAlign:"center",
    width:"60%",color:"rgba(59, 66, 75, 1)",
    margin: "0 auto",
   
    
    }} >

<Button variant="contained" sx={{
    background:"rgba(10, 200, 188, 0.2)",
    color:"rgba(32, 65, 140, 1)",
    borderRadius:"5px",

    fontSize:"14.5px",
    
    marginBottom:"3vh",
    fontFamily: "Urbanist, sans-serif",

    fontWeight:"700",
    width:"150px",
    height:"40px",
    textTransform:"capitalize",
    boxShadow: 'none', // Remove any hover effect like shadow

    '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"142px",
      fontSize:"13.5px",
      marginBottom:"2vh",
      marginTop:"1%",



   
    }
}}>WHAT WE OFFER</Button>

        <Typography variant='h1' sx={{fontWeight:"700",      
          fontSize:"46px",
          marginBottom:"2vh",
          fontFamily: "Urbanist, sans-serif",

         '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
               fontSize:"34px",
               width:"100%"
              },
        }} >
        Our Products
        </Typography>
        <Typography paragraph sx={{color:"rgba(121, 126, 132, 1)",
        fontSize:"16px",
        width:"75%",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
          width:"124%",
          fontSize:"14px",

         }
        }} >
        Airigo offers a comprehensive range of high-performance server solutions built for the future; designed to meet the evolving needs of your business. 
        </Typography>
    </Box>
    

<Box sx={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20vh",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                flexDirection:"column",
               alignItems:'center',
               marginTop:"15vh",
              },

}} >
    <Box sx={{width:"460px",height:"420px",
        borderRadius:"10px", 
  
    background:"#ffffff",
    boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display:"flex",
    flexDirection:"column",
    position:"relative",
    textAlign:"center",
    alignItems:"center",
    fontFamily: "Urbanist, sans-serif",

    marginRight:"8vh",
    '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"300px",
      height:"380px",
      marginRight:"0vh",
      
                       
      },
    
    }} >

    <Box sx={{position:"absolute",top:"-20%"}} >
    <img src={offerimageone} alt=""   width="80%" loading='lazy'  />
    </Box>

      
      <Typography variant='h4' sx={{
      fontSize:"30px",
      fontWeight:"700",
      color:"rgba(59, 66, 75, 1)",
      textAlign:"center",
      fontFamily: "Urbanist, sans-serif",

      marginTop:"40%",
      marginBottom:"2vh",
      '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
     
      marginTop:"40%",
      fontSize:"22px",

                       
      },
      }} >
      Modular Servers Series
      </Typography>
      <Typography paragraph sx={{
      color:"rgba(121, 126, 132, 1)",
      width:"90%",
      fontWeight:"500",
      textAlign:"center",
      fontSize:"16px",              
      fontFamily: "Urbanist, sans-serif",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
        fontSize:"14px",  
        width:"90%",
            

      }
}} >
      Airigo's modular server series offers superior scalability with compute modules supporting additional CPUs. The system utilizes modern controllers and comes with a range of chassis options for flexible configurations, maximizing performance for demanding applications.
      </Typography>
      <Link to="/ModularServersSeries" >
      <Typography sx={{
        color:"rgba(10, 200, 188, 1)",
      textDecoration:"underline",
      fontFamily: "Urbanist, sans-serif",
      fontWeight:"600",
      }} >Read More</Typography>

      </Link>

    </Box>
    <Box sx={{width:"460px",height:"420px", 
    borderRadius:"10px", 
    background:"#ffffff",
    boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display:"flex",
    flexDirection:"column",
    position:"relative",
    fontFamily: "Urbanist, sans-serif",

    textAlign:"center",
    alignItems:"center",
    marginLeft:"8vh",
    '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"300px",
      height:"380px",
      marginLeft:"0vh",
      marginTop:"20vh",
                       
      },
    }} >

    <Box sx={{position:"absolute",top:"-25%"}} >
    <img src={offerimagetwo} alt=""   width="60%" loading='lazy'  />
    </Box>

      
    <Typography variant='h4' sx={{
      fontSize:"30px",
      fontWeight:"700",
      color:"rgba(59, 66, 75, 1)",
      textAlign:"center",
      fontFamily: "Urbanist, sans-serif",

      marginTop:"40%",
      marginBottom:"2vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
       fontSize:"22px",
      marginTop:"50%",
                       
      },
      }} >
  
Data Center Rack Series
      </Typography>
      <Typography paragraph sx={{
      color:"rgba(121, 126, 132, 1)",
     fontWeight:"500",
      width:"90%",
      textAlign:"center",
      fontSize:"16px",      
      fontFamily: "Urbanist, sans-serif",
      '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
        width:"90%",
        fontSize:"14px"
      }
}} >
      Airigo's Data Center series offers scalable server racks for all data center sizes. They accommodate  free cooling systems and maximize resource efficiency with monitoring and control features.      </Typography>
   <Link to="/DataCenterRackSeries" >
   <Typography sx={{
    color:"rgba(10, 200, 188, 1)",
    fontFamily: "Urbanist, sans-serif",
   textDecoration:"underline",
   fontWeight:"600",
   marginTop:"2vh"}} >Read More</Typography>

   </Link>

    </Box>

</Box> 

<Box sx={{textAlign:"center", marginTop:"6vh"}} >
<Link to="/OurProducts"  style={{textDecoration:"none"}} >
<Button variant="contained" sx={{
              background:"rgba(10, 200, 188, 1)",
              borderRadius:"10px",
              fontSize:"16px",
              fontWeight:"500",
              width:"245px",
              fontFamily: "Urbanist, sans-serif",

              height:"50px",
              textTransform:"capitalize",
             
             }} >View All Products</Button>
</Link>

</Box>

    
    </Box>
    </div>
  )
}

export default Whatweoffer
