import React from 'react'
import Maincardimage from '../../asserts/ProjectOverView/Modularseriescardimage.png'
import { Box, Typography } from '@mui/material'


function Projectoverviewspecifications() {
  return (
    <div className='Projectoverviewspecifications' >
  
      <div className='Projectoverviewspecifications-left' >
      <div className='Projectoverviewspecifications-leftCard'>
            <h3 className='Specifications-heading'>Specifications</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul  className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                  10 servers in chassis
                  </li>
                  <li>
                  480 cores, 15 TB RAM max.
                   
                  </li>
                  <li>
                  80 SSD M.2 (22110) (raw-64 TB) max.
                  </li>
                  <li>
                  140x 1-40 Gbps & 20x 100/200 Gbps max.
                  </li>
                  <li>
                  Power consumption typical - 1,2 KW
                  </li>
                  <li>
                  Power consumption peak - 4,5 KW
                  </li>
                  <li>
                    Performance 7,7 Tflops max
                  </li>
                
                </ul>
              </Box>
            </Box>
          </div>
  <div className='Projectoverviewspecifications-leftCard'>
            <h3 className='Specifications-heading'>Specifications</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                  5 servers in chassis
                  </li>
                  <li>
                  480 cores, 15 TB RAM max.
                  </li>
                  <li>
                  80 SSD M.2 (22110) (raw-64 TB) max.
                  </li>
                  <li>
                  70x 1-40 Gbps & 10x 100/200 Gbps max.
                  </li>
                  <li>
                  Power consumption typical - 1,1 KW
                  </li>
                  <li>
                  Power consumption peak - 4,2 KW
                  </li>
                  <li>
                  Performance 7,7 Tflops max.
                 </li>
                  
                </ul>
              </Box>
            </Box>
          </div>
    

      </div>
      <div className='Projectoverviewspecifications-center' >
      <h2 className='Overview-center-heading' >Product Overview</h2>
 <div className='Projectoverviewspecifications-MainCard' >
<img src={Maincardimage} alt=""  width="60%"/>
<h2>MODULAR SERIES</h2>
 </div>
</div>
       <div className='Projectoverviewspecifications-right' >


       <div className='Projectoverviewspecifications-rightCard'>
            <h3 className='Specifications-heading'>Specifications</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                  5 servers in chassis
                  </li>
                  <li>
                  240 cores, 7,5 TB RAM max.
                  </li>
                  <li>
                  40 SSD M.2 (22110) (raw-32 TB) max.
                  </li>
                  <li>
                  70x 1-40 Gbps & 10x 100/200 Gbps max.
                  </li>
                  <li>
                  Power consumption typical - 1,4 KW
                  </li>
                  <li>
                  Power consumption peak -5,4 KW
                  </li>
                  <li>
                  Performance 100,9 Tflops max.
                          </li>
                </ul>
              </Box>
            </Box>
          </div>
 <div className='Projectoverviewspecifications-rightCard'>
            <h3 className='Specifications-heading'>Specifications</h3>
            <Box sx={{ display: "flex", marginBottom: "5vh", marginLeft: "5%" }}>
              <Box sx={{
                '@media (max-width:700px)': { marginLeft: "5%" },
              }}>
                <ul className='unordered-list-card' style={{paddingInlineStart: '0' }}>
                  <li>
                  5 servers in chassis
                  </li>
                  <li>
                  240 cores, 7,5 TB RAM max.
                  </li>
                  <li>
                  SSD M.2 (22110) (raw-32 TB) max.
                  </li>
                  <li>
                  70x 1-40 Gbps & 10x 100/200 Gbps max.
                  </li>
                  <li>
                    Unification of components
                  </li>
                  <li>
                  Power consumption typical - 0,6 KW
                  </li>
                  <li>
                  Power consumption peak - 2,8 KW
                  </li>
                  <li>
                  Performance 3,8 Tflops max.
                  </li>
                </ul>
              </Box>
            </Box>
          </div>

</div>
    </div>
  )
}

export default Projectoverviewspecifications
