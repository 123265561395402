import React, { useEffect } from 'react'
import Projectoverviewform from './Projectoverviewform'
import Applicationsofproducts from './Applicationsofproducts'
import Projectoverviewonemain from './Projectoverviewonemain'
import Projectoverviewspecifications from './Projectoverviewspecifications'
import Versitilecomp from './Versitilecomp'


function Projectoverviewone() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <div>
    <Projectoverviewonemain/>
    <Projectoverviewspecifications/>
    <Versitilecomp/>

    <Applicationsofproducts/>
    <Projectoverviewform/>
    </div>
  )
}

export default Projectoverviewone
