import React from 'react';
import './Aboutus.css'
import  RightImage from '../../asserts/Aboutuspage/Rectangle 22.png'
import  LeftImage from '../../asserts/Aboutuspage/Rectangle 28.png'


function Oursolution() {
  return (
    <div className='Oursolution' >
      <div className='Oursolution-LeftImage' >
      <img src={LeftImage} alt="" width="75%" className='LeftImageOurSolutions' loading='lazy' />
      </div>
      <div className='Oursolution-Center' >
      <h4>OUR SOLUTION</h4>
<h1>Bridging the Gap: Airigo's Future-Proof Server Solutions</h1>
<p>Airigo Multiventures Private Limited stands at the forefront of innovation with its bespoke suite of server and supercomputer solutions, designed to meet the evolving demands of AI and datacentric applications. Their specialized hardware, underpinned by the energy-efficient ARMx64 architecture, provides a modular and scalable foundation for businesses to cost-effectively expand their infrastructure.</p>
<p>As technology races towards a future dominated by advanced computational intelligence, Airigo's cutting-edge and environmentally conscious platforms empower organizations to unlock the full potential of GenerativeAI, machine learning, and expansive data processing, ensuring they remain competitive in an ever-changing digital landscape.</p>
</div>
  <div className='Oursolution-RightImage' >
<img src={RightImage} alt="" width="75%" className='RightImageOurSolutions' loading='lazy' />
</div>
      
    </div>
  )
}

export default Oursolution
