import React from 'react'
import './Aboutus.css';
import { Button } from '@mui/material';
import Icon01 from '../../asserts/Aboutuspage/Aboutusicons/Group 22.png'
import Icon02 from '../../asserts/Aboutuspage/Aboutusicons/Group 23.png'
import Icon03 from '../../asserts/Aboutuspage/Aboutusicons/Group 24.png'
import Icon04 from '../../asserts/Aboutuspage/Aboutusicons/Group 25.png'
import Icon05 from '../../asserts/Aboutuspage/Aboutusicons/Group 26.png'
import Icon06 from '../../asserts/Aboutuspage/Aboutusicons/Group 27.png'



function Whychooseus() {
  return (
    <div className='whychooseus' >
    <div className='whychooseus-content' >
    <Button variant="contained" sx={{
            background:"rgba(206, 244, 242, 1)",
            color:"rgba(32, 65, 140, 1)",
            borderRadius:"5px",
            fontSize:"13.5px",
            fontWeight:"700",
            width:"155px",
            fontFamily: "Urbanist, sans-serif",
            boxShadow: 'none', // Remove any hover effect like shadow
            height:"40px",
            textTransform:"capitalize",
            '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(206, 244, 242, 1)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },

           }} >WHY CHOOSE US</Button>
        <h1>Our Key Differentiators</h1>
    </div>
      <div className='Differentiators'>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon01} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
<h4>Specialized Performance- Optimized Hardware</h4>
<p>Airigo builds next-gen servers for AI. Their ARMx64 architecture optimizes AI processors and minimizes latency, empowering businesses to lead in AI and machine learning.</p>
</div>
        </div>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon02} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
<h4>Cost-Effective AI Infrastructure</h4>
<p>Airigo's cost-effective AI infrastructure cuts data center expenses by up to 15% and slashes infrastructure costs by 60%. Their ARMx64 architecture delivers lower power consumption and high performance for demanding AI applications.</p>
</div>
        </div>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon03} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
         <h4>Energy-Efficient Performance</h4>
         <p>Airigo leads in eco-friendly AI with ARMx64 servers. They deliver high performance for AI tasks while reducing energy consumption and operational costs, making them a powerful and sustainable choice.</p>
</div>
        </div>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon04} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
         <h4>Future-Proof Computing</h4>
         <p>Airigo builds future-proof servers for AI and massive data. Their scalable design adapts to advancements, ensuring clients stay competitive in the ever-evolving technological landscape.</p>
</div>
        </div>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon05} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
         <h4>Open Architecture Ecosystem</h4>
         <p>Airigo's open ARMx64 servers offer flexibility with a choice of CPUs from AMD & Ampere. This allows for customized configurations to meet specific needs and budgets.</p>
</div>
        </div>
        <div className='Differentiators-Cards'>
         <div className='Differentiators-Cards-IconSection' >
<img src={Icon06} alt=""  width="65%" />
         </div>
         <div className='Differentiators-Cards-ContentSection' >
         <h4>Modular & Scalable Infrastructure</h4>
         <p>Airigo's modular servers easily scale with new CPUs, memory, and AI accelerators. This future-proofs your data center for performance without expensive upgrades.</p>
</div>
        </div>
      </div>
    </div>
  )
}

export default Whychooseus
