import React, { useEffect } from 'react'
import Contactusbanner from './Contactusbanner'
import Contactusform from './Contactusform'


function Contactus() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <div>
      <Contactusbanner/>
      <Contactusform/>
    </div>
  )
}

export default Contactus
