import { Box, Button, Typography } from '@mui/material';
import React from 'react';




function Ourproductheading() {
  return (
    <div>
      <Box sx={{
        marginTop:"20vh",
      display:"flex",
      flexDirection:"column",
     
      alignItems:"center",
      justifyContent:"center",

      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)

marginTop:"18vh",
      }
      }} >

<Button variant="contained" sx={{
            background:"rgba(206, 244, 242, 1)",
            color:"rgba(32, 65, 140, 1)",
            borderRadius:"5px",
            fontSize:"14.5px",
        fontWeight:"700",
    fontFamily: "Urbanist, sans-serif",
            width:"160px",
            height:"40px",
            textTransform:"capitalize",
            marginBottom:"2vh",
            boxShadow: 'none', // Remove any hover effect like shadow

            
            '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    }
           }} >WHAT WE OFFER</Button>

         <Typography variant='h2' sx={{
            color:"rgba(59, 66, 75, 1)",
          fontSize:"46px",
      fontFamily: "Urbanist, sans-serif",
      fontWeight:"700",
      '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
         fontSize:"32px",
      }
          }} >
         Our Products
         </Typography>

<Typography paragraph sx={{fontFamily: "Urbanist, sans-serif",
width:"50%",
color:"rgba(121, 126, 132, 1)",
        fontSize:"16px",
        fontWeight:"500",
        marginTop:"2vh",
textAlign:"center",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
   width:"85%",
 }
}} >
Airigo offers a comprehensive range of high-performance server solutions built for the future; designed to meet the evolving needs of your business. 
</Typography>

      </Box>
    </div>
  )
}

export default Ourproductheading
