import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import './Homeaboutuscardsection.css'
import { Link } from 'react-router-dom'


function Homeaboutus() {
  return (
    <div style={{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      alignSelf:"center",
    }} >
 <div style={{
      maxWidth:"1650px",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      alignSelf:"center",
    }} >
      <Box sx={{ 
      width: "100%", 
      display:"flex",
      justifyContent:"center",
      height:"100%",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                flexDirection:"column",
                height:"90%",
                marginTop:"0%",

                
              
              },
      
      }} >
      <Box sx={{
      width:"50%",
      height:"100%",
      marginLeft:"5%", 
      background:"#ffffff",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
        width:"90%",
        marginLeft:"10%"
              },
      
       }}  >
     <Button variant="contained" sx={{
    background:"rgba(10, 200, 188, 0.2)",
    color:"rgba(32, 65, 140, 1)",
    borderRadius:"5px",
    fontSize:"14.5px",
    fontWeight:"700",
    fontFamily: "Urbanist, sans-serif",
    width:"110px",
    height:"40px",
    textTransform:"capitalize",
    boxShadow: 'none', // Remove any hover effect like shadow

    '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },

    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"92px",
      fontSize:"11.5px",

    }
}}>ABOUT US</Button>

      <Typography variant='h2' sx={{
        fontSize:"46px",
        fontWeight:"700",
        fontFamily: "Urbanist, sans-serif",
        width:"90%",

        marginTop:"3vh",
        lineHeight:"56px",
        color:"rgba(59, 66, 75, 1)",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '25px',
                width:"90%",
                marginTop:"2vh",
                lineHeight:"28px",
              },

      }} >
      Airigo: Delivering global server excellence through Indian innovation
      </Typography>
      <Typography paragraph sx={{
        color:"rgba(121, 126, 132, 1)",
        fontSize:"16px",
        fontWeight:"500",
        marginTop:"2vh",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '14px',
                width:"90%",
                marginTop:"2vh",
              },
      }} >
      Airigo builds cutting-edge modular servers and data centers, empowering businesses with high-performance computing solutions for the modern data landscape.
      </Typography>
      <Box sx={{ 
      display: "flex", 
      marginBottom: "5vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
               flexDirection:"column",
              },
      
       }}>
            <Box sx={{ marginRight: "5vh",
             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                marginRight:"0vh",
              },
            }}>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Technological Advancement {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Excellence in Computing {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Fostering Innovation {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Support 'Make in India' {/* Add a span with the bullet class */}
          </Typography>
          
            </Box>

            <Box sx={{ marginRight: "5vh",
             '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                marginRight: '1vh',
              },
            }}>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Empowering India's Global Tech Position {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Cost-Effective AI Infrastructure {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",
              }}
            ></span> Future-Proof Computing {/* Add a span with the bullet class */}
          </Typography>
          <Typography paragraph 
          sx={{ 
          color:"#181818" ,
          fontSize:"15px",
          fontWeight:"500",
          fontFamily: "Urbanist, sans-serif",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                color:"#181818",
                width: "7px",
                height: "7px",
                borderRadius: "50%",
                background: "rgba(10, 200, 188, 1)",
                marginRight:"5px",

              }}
            ></span> Open Architecture Ecosystem </Typography>
          
            </Box>
          </Box>
          <Link to="/About" style={{textDecoration:"none"}}  >
          <Button variant="contained" sx={{
              background:"rgba(10, 200, 188, 1)",
              borderRadius:"10px",
              fontSize:"16px",
              fontFamily: "Urbanist, sans-serif",
              fontWeight:"400",
              width:"180px",
              height:"45px",
              textTransform:"capitalize",
              '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                marginTop:"-8%"
              }

             }} >Know More</Button>
          </Link>

     
        </Box>
        <Box sx={{width:"50%",
        height:"100%",
        marginRight:"5%",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                width:"90%",
                marginTop:"10%",
                marginBottom:"10%",
              },

         }}  >
           <div className='Homeaboutuscardsection' >
              <div className='CardOneAboutus' >
          <h1>60%</h1>
          <p>more cost efficient </p>
              </div>
              <div className='CardOneAboutus' >
              <h1>3X</h1>
          <p>faster processors</p>
              </div>
              <div className='CardOneAboutus' >
              <h1>15%</h1>
          <p>more energy efficient</p>
              </div>
              <div className='CardOneAboutus' >
              <h1>100%</h1>
          <p>customisable</p>
              </div>
             
             
           </div>
        </Box>
      </Box>
    </div>
    </div>
   
  )
}

export default Homeaboutus


