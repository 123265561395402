import React from 'react';
import './Preloader.css'
import AnimJson from './Preloaderanim.json'
import Lottie from 'lottie-react';

function Preloader() {
  return (
    <div className='Preloader' >
    <div className='AnimJsonSection' >
    <Lottie animationData={AnimJson} />
    </div>
    </div>
  )
}

export default Preloader
