import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Aboutuscontentimg from '../../../src/asserts/Aboutuspage/Group 43.webp';


function Aboutuscontent() {
  return (
    <div>
        <Box sx={{ width: "100%", display:"flex",justifyContent:"center", background: "rgba(32, 65, 140, 1)",height:"100%",paddingTop:"10vh",marginTop:"-5vh",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              height:"115vh",
              flexDirection:"column",
            },
          }} >
    
    
    <Box sx={{width:"48%",height:"100%", background:"rgba(32, 65, 140, 1)",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"90%",
            height:"65%",
            marginLeft:"10%",
            },
     }}  >
    <Button variant="contained" sx={{
            background:"rgba(206, 244, 242, 1)",
            color:"rgba(32, 65, 140, 1)",
            borderRadius:"5px",
            fontSize:"13.5px",
            fontWeight:"700",
            fontFamily: "Urbanist, sans-serif",
            boxShadow: 'none', // Remove any hover effect like shadow

            width:"105px",
            height:"40px",
            textTransform:"capitalize",
            '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(206, 244, 242, 1)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"92px",
      fontSize:"11.5px",
      height:"36px",




    }

           }} >ABOUT US</Button>
    <Typography variant='h2' sx={{
      fontSize:"46px",
      fontWeight:"700",
      width:"80%",
      fontFamily: "Urbanist, sans-serif",

      color:"#ffffff" ,
      marginTop:"3vh",
      '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
        fontSize:"26px",
        width:"95%",

      }

    }} >
    Airigo: Delivering global server excellence through Indian innovation
    </Typography>
    <Typography paragraph sx={{
    color:"#ffffff" ,
    fontSize:"16px",
    fontWeight:"500",
    width:"75%",
    marginTop:"4%",
    fontFamily: "Urbanist, sans-serif",

    marginBottom:"4%",
    '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
      marginTop:"3vh",
      marginBottom:"3vh",
      fontSize:"14px",
      width:"90%",



    }

    }} >
    Airigo builds cutting-edge modular servers and data centers, empowering businesses with high-performance computing solutions for the modern data landscape.
    </Typography>
    <Box sx={{ 
      display: "flex",
       marginBottom: "5vh",
    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              flexDirection:"column",
            },
     }}>
          <Box sx={{ marginRight: "5vh"}}>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Technological Advancement {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Excellence in Computing {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Fostering Innovation {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Support 'Make in India' {/* Add a span with the bullet class */}
        </Typography>
        
          </Box>

          <Box sx={{ marginRight: "5vh"}}>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Empowering India's Global Tech Position {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Cost-Effective AI Infrastructure {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",
            }}
          ></span> Future-Proof Computing {/* Add a span with the bullet class */}
        </Typography>
        <Typography paragraph 
        sx={{ 
        color:"#ffffff" ,
        fontSize:"15px",
        fontWeight:"500",
        fontFamily: "Urbanist, sans-serif",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize: '12px',
            },
        }} >
          <span
            style={{
              content: "''",
              display: "inline-block",
              color:"#181818",
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              background: "rgba(10, 200, 188, 1)",
              marginRight:"5px",

            }}
          ></span> Open Architecture Ecosystem </Typography>
        
          </Box>
        </Box>
   
      </Box>
      <Box sx={{width:"42%",height:"100%", background:"rgba(32, 65, 140, 1)" ,display:"flex",alignItems:"center",justifyContent:"center",
              '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                width:"100%",
                height:"60%",
              }
      }}  >
        <img src={Aboutuscontentimg}  className='about-us-svgimage' alt="" width="95%" loading='lazy' />
      </Box>
    </Box>
    </div>
  )
}

export default Aboutuscontent
