import React, { useRef } from 'react'
import { Button, TextField } from '@mui/material'
import ContactUsImage from '../../asserts/ContactUs/ContactFormImage.png'
import './Projectoverview.css'
import { toast } from 'react-toastify'; // Import toast
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import emailjs from 'emailjs-com';


function Projectoverviewform() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Trim the values to remove leading and trailing whitespace
    const formData = new FormData(form.current);
    const formValues = Object.fromEntries(formData.entries());
    const trimmedFormValues = Object.fromEntries(
      Object.entries(formValues).map(([key, value]) => [key, value.trim()])
    );

    // Check if any field is empty after trimming
    if (Object.values(trimmedFormValues).some(value => value === '')) {
      toast.error('Please fill out all fields'); // Display error toast
      return;
    }

    emailjs
      .sendForm(
        'service_pgws0pi', // replace with your service ID
        'template_5vcg65q', // replace with your template ID
        form.current,
        'vSQhxF5WARatgmfU7' // replace with your user ID
      )
      .then(
        (result) => {
          console.log('Email successfully sent:', result.text); 
          toast.success('Email successfully sent'); // Display success toast
        },
        (error) => {
          console.error('Email sending failed:', error.text);
          toast.error('Email sending failed'); // Display error toast
        }
      );

    e.target.reset();
  }


  return (
    <>
      <ToastContainer/>
      <div>
        <div className='GetistouchContactus-projectoverview'>
        <div className="contact-information-projectoverview" >
        <img src={ContactUsImage} alt="" width="85%" />
        </div>
        <div className='contact-from-project-overview'>
        <Button variant="contained" sx={{
    background:"rgba(10, 200, 188, 0.2)",
    color:"rgba(32, 65, 140, 1)",
    borderRadius:"5px",
    fontSize:"14.5px",
    fontWeight:"700",
    fontFamily: "Urbanist, sans-serif",
    width:"160px",
    height:"45px",
    textTransform:"capitalize",
    boxShadow: 'none', // Remove any hover effect like shadow
    marginTop:"1%",
       '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },

    '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
      width:"125px",
      fontSize:"11.5px",

    }
}}>LET’S CONNECT</Button>
        
        <h2 style={{color:"rgba(59, 66, 75, 1)"}} className='HaveQuestionHeading' >Have questions regarding this product?</h2>
        <div className='contact-inputs-section'>
            <form ref={form} onSubmit={sendEmail} >
              <TextField
                id="firstname"
                name="firstname"
                type='text'
                placeholder='First Name'
                InputProps={{ style: { color: 'black',fontFamily:  "Urbanist, sans-serif", } }}
                variant="outlined"
              
                required
                sx={{
                  mb: 4,

                  width:"41.5%",
                  marginRight:"4vh",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#4C4C4C',
                      border: "none"
                    },
                    '&:hover fieldset': {
                      borderColor: '#4C4C4C'
                    }
                  },
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"90%"
                  }

                }}
              />
              <TextField
                id="lastName"
                name="lastName"
                type='text'
                placeholder='Last Name'
                InputProps={{ style: { color: 'black',fontFamily:  "Urbanist, sans-serif", } }}
                variant="outlined"
              
                required
                sx={{
                  mb: 4,
                  width:"41.5%",
                  marginRight:"2vh",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#4C4C4C',
                      border: "none"
                    },
                    '&:hover fieldset': {
                      borderColor: '#4C4C4C'
                    }
                  },
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"90%"
                  }
                }}
              />
              <TextField
                id="mobileNumber"
                name="mobileNumber"
                placeholder='Mobile Number'
                InputProps={{ style: { color: 'black',fontFamily:  "Urbanist, sans-serif", } }}
                variant="outlined"
              
                required
                sx={{
                  mb: 4,
                  width:"41.5%",
                  marginRight:"4vh",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#4C4C4C',
                      border: "none"
                    },
                    '&:hover fieldset': {
                      borderColor: '#4C4C4C'
                    }
                  },
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"90%"
                  }

                }}
              />
               <TextField
                id="email"
                name="email"
                type='email'
                placeholder='Email'
                InputProps={{ style: { color: 'black',fontFamily:  "Urbanist, sans-serif", } }}
                variant="outlined"
              
                required
                sx={{
                  mb: 3,
                  width:"41.5%",
                  marginRight:"2vh",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#4C4C4C',
                      border: "none"
                    },
                    '&:hover fieldset': {
                      borderColor: '#4C4C4C'
                    }
                  },
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"90%"
                  }
                }}
              />
              <TextField
                id="message"
                name="message"
                placeholder='Message'
                variant="outlined"
                multiline
                rows={3}
                InputProps={{ style: { color: 'black',fontFamily:  "Urbanist, sans-serif", } }}
                
                required
                sx={{
                  mb: 2,
                  width:"87%",
                  border: "1px solid rgba(217, 217, 217, 1)",
                  borderRadius: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(217, 217, 217, 1)',
                      border: "none"
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(217, 217, 217, 1)',
                    }
                  },
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                  width:"90%"
                  }
                }}
              />
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  border: '1.2px solid white',
                  color: 'white',
                  width: '140px',
                  fontFamily: "Urbanist, sans-serif",
                  fontSize:"16px",
                  height: '50px',
                  borderRadius: '12px',
                  background:"rgba(10, 200, 188, 1)",
textTransform:"capitalize",
                  '&:hover': {
                    backgroundColor: 'rgba(10, 200, 188, 1)',
                    color: "#000",
                    border: "#fff" // Set the hover background color to transparent
                  },
                }}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
   
  )
}

export default Projectoverviewform
