import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Ourtechnologyimage from '../../../src/asserts/Aboutuspage/Ourtechnologyimage.png'


function Ourtechnology() {
  return (
    <div>
       <Box sx={{ 
        width: "100%", 
       display:"flex",
       justifyContent:"center", 
       background: "#ffffff",
       marginTop:"5%",
       height:"100%" ,
       marginBottom:"5vh",

       '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
         flexDirection:"column",
         height:"85vh" ,
         marginTop:"15vh",
         marginBottom:"5vh",
       },
       }} >
    
    
    <Box sx={{width:"45%",height:"100%", background:"#ffffff",
     '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
        width:"90%"
       },
     }}  >
    <Button variant="contained" sx={{
            background:"rgba(10, 200, 188, 0.2)",
            color:"rgba(32, 65, 140, 1)",
            borderRadius:"5px",
            fontSize:"13.5px",
            fontWeight:"700",
            width:"160px",
            height:"40px",
            fontFamily: "Urbanist, sans-serif",
            boxShadow: 'none', // Remove any hover effect like shadow

            '&:hover': {
      cursor:"default",
        backgroundColor: 'rgba(10, 200, 188, 0.2)', // Keep the same background color on hover
        color: 'rgba(32, 65, 140, 1)', // Keep the same text color on hover
        boxShadow: 'none', // Remove any hover effect like shadow
    },

            textTransform:"capitalize",
            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              width:"145px",
              fontSize:"12px",


        marginLeft:"10%"
       },

           }} > OUR TECHNOLOGY</Button>
    <Typography variant='h2' sx={{
      fontSize:"46px",
      fontWeight:"700",
      color:"rgba(59, 66, 75, 1)",
      fontFamily: "Urbanist, sans-serif",
     width:"80%",
      marginTop:"4vh",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
         fontSize:"27px",
         marginLeft:"10%",
         marginTop:"2vh",
         width:"92%",


       },

    }} >
From air-cooled to high-performance: Airigo's key product features    </Typography>
    <Typography paragraph sx={{
      color:"rgba(121, 126, 132, 1)",
      fontSize:"16px",
      fontWeight:"500",
      marginTop:"4vh",
      fontFamily: "Urbanist, sans-serif",
      width:"72%",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
         fontSize:"14px",
         marginLeft:"10%",
         marginTop:"2vh",
         width:"84%",


       },

    }} >
Airigo's Data Center series offers server racks designed for data centers of all sizes. The racks come in various configurations and can house air or chiller cooling systems. Airigo highlights features that maximize resource efficiency, including monitoring and control systems. Some racks can hold up to 8 chassis and 80 servers, with a maximum processing power of 950 Tflops.    </Typography>
    
      </Box>
      <Box sx={{width:"45%",
      height:"100%", 
      background:"#ffffff",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
        width:"90%",
        marginLeft:"5%",
        height:"40%", 
       },
      }}  >
        <img src={Ourtechnologyimage} alt="" width="92%" style={{borderRadius:"15px"}}  loading='lazy' />
      </Box>
    </Box>
    </div>
  )
}

export default Ourtechnology
