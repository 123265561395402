import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./theme/theme";
import PageRoutes from "./routes/routes";
import Preloader from "./components/Preloader";

const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (you can replace this with actual loading logic)
    setTimeout(() => {
      setLoading(false);
    }, 4000); // Example: 2 seconds
  }, []);


  return (
    <ThemeProvider theme={muiTheme}>
      {loading ? <Preloader /> : <PageRoutes />}
    </ThemeProvider>
  );
};

export default App;
