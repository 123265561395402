import React, { useEffect } from 'react'
import Ourtechnology from './Ourtechnology'
import Whatweoffer from './Whatweoffer';
import Ourproductheading from './Ourproductheading';


function Ourproducts() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <div>
    <Ourproductheading/>
   <Whatweoffer/>
    <Ourtechnology/>
    </div>
  )
}

export default Ourproducts
